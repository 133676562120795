import React from 'react';
import {
  Button,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import directus from '../../../services/directus';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../views/Components/ErrorMessage';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import EventEmitter from 'src/utils/EventEmitter';
import {
  useStylesEditSession,
  DeleteButton,
  SaveButton,
} from './style.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { TimeRange, TimeRelation } from 'datetime-period';

export default function SaveTimesheetEntry(props) {
  const classes = useStylesEditSession();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  var disabledCheck = false;
  var multipleSession = '';

  if (!props.fullDay && props.publicHoliday) {
    if (props.session.session_type.show_start_end_time) {
      disabledCheck = props.startTime === "Invalid date" || props.endTime === "Invalid date";
    }

    if (props.session.session_type.show_duration || (props.session.session_type.show_duration_admin_only)) {
      disabledCheck = props.sessionDuration === null || parseTimeSpan(props.sessionDuration) === 0;
    }

    if (props.session.session_type.show_start_end_time && props.session.session_type.show_duration || (props.session.session_type.show_duration_admin_only)) {
      disabledCheck = (props.startTime === "Invalid date" || props.endTime === "Invalid date") && (props.sessionDuration === null || parseTimeSpan(props.sessionDuration) === 0);
    }
  }
  else {
    disabledCheck = false;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveEditSessionDrawer = (text) => {
    EventEmitter.emit('saveEditSessionDrawer', {
      text: text,
      info: 'editsession'
    })
  }

  function parseTimeSpan(timeString) {
    let parts = timeString.split("h");
    let parts2 = timeString.split("m");
    if (parts.length > 1 || parts2.length > 1) {
      if (parts.length === 1) {
        return (parseInt(parts[0].slice(0, -1), 10))
      }
      else {
        return (parseInt(parts[0], 10) * 60) + (parseInt(parts[1].slice(0, -1) | 0, 10))
      }
    }
    else {
      var decimalTimeString = timeString;
      var n = new Date(0, 0);
      n.setMinutes(+decimalTimeString * 60);
      return ((moment(n).hour() * 60) + moment(n).minute());
    }
  }

  function getAMPM(time) {
    const hours = time.slice(0, 2);
    if (hours >= 12) {
      return 'PM';
    }
    else {
      return 'AM';
    }
  }

  function checkPMAM(startTime, endTime) {
    var checkStartTime = getAMPM(startTime);
    var checkEndTime = getAMPM(endTime);

    if (checkStartTime === 'PM' && checkEndTime === 'AM') {
      return 'PMAM';
    }
    else if (checkStartTime === 'AM' && checkEndTime === 'AM') {
      return 'AMAM';
    }
    else if (checkStartTime === 'PM' && checkEndTime === 'PM') {
      return 'PMPM';
    }
    else {
      return 'AMPM';
    }
  }

  function checkDistance(start, end) {
    if (end != null) {
      var diff;
      if (start > end) {
        diff = moment.duration(moment(moment('1991-01-02T' + end).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + start).format('YYYY-MM-DDTHH:mm:00')));
      }
      else {
        diff = moment.duration(moment(moment('1991-01-01T' + end).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + start).format('YYYY-MM-DDTHH:mm:00')));
      }

      if (diff.hours() * 60 + diff.minutes() > 720) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  const SessionUpdateDelete = async (mode) => {
    setLoading(true);
    var SessionsFilter = [];
    let editData = null;
    var StartInside = [];
    var EndInside = [];
    var InsideStartTouching = [];
    var InsideEndTouching = [];
    var Enclosing = [];
    var EnclosingStartTouching = [];
    var EnclosingEndTouching = [];
    var Inside = [];
    var AddInside = [];
    var ExactMatch = [];
    var session_date = '';
    var overlapDetect = false;
    var timeRangeB = '';

    if (mode === 'edit') {
      // ============ Check Overlap========================//
      if (props.session.session_type.show_start_end_time && (props.startTime !== null && props.endTime !== null)) {
        SessionsFilter = await directus.getItems('sessions', {
          fields: '*',
          filter:
          {
            employee: { eq: props.employeeId },
            id: { neq: props.sessionID },
            session_date: { in: [dayjs(props.selectedDate).format('YYYY-MM-DD'), dayjs(props.selectedDate).add(1, 'day').format('YYYY-MM-DD'), dayjs(props.selectedDate).add(-1, 'day').format('YYYY-MM-DD')] },
          },
          limit: -1,
        });

        if (checkDistance(props.startTime, props.endTime)) {
          multipleSession = window.confirm("Session is more than 12 hours long. Are you sure this is correct?");
          if (multipleSession === false) {
            setLoading(false);
            return
          }
        }

        if (checkPMAM(props.startTime, props.endTime) === 'PMAM') {
          timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).add(1, 'day').format('YYYY-MM-DD')) + ' ' + props.endTime));
        }
        else if (checkPMAM(props.startTime, props.endTime) === 'AMPM') {
          timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).format('YYYY-MM-DD')) + ' ' + props.endTime));
        }
        else if (checkPMAM(props.startTime, props.endTime) === 'AMAM') {
          if (checkDistance(props.startTime, props.endTime)) {
            timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).add(1, 'day').format('YYYY-MM-DD')) + ' ' + props.endTime));
          }
          else {
            timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).format('YYYY-MM-DD')) + ' ' + props.endTime));
          }
        }
        else if (checkPMAM(props.startTime, props.endTime) === 'PMPM') {
          if (checkDistance(props.startTime, props.endTime)) {
            timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).add(1, 'day').format('YYYY-MM-DD')) + ' ' + props.endTime));
          }
          else {
            timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).format('YYYY-MM-DD')) + ' ' + props.endTime));
          }
        }

        if (SessionsFilter.data.length > 0) {
          SessionsFilter.data.map((data) => {
            if (!data.session_start_time || !data.session_end_time) {
              // session does not have a start or end time set. Skip the validation check against this record.
              return;
            }

            if (checkPMAM(data.session_start_time, data.session_end_time) === 'PMAM') {
              session_date = dayjs(data.session_date).add(1, 'day').format('YYYY-MM-DD');
            }
            else if (checkPMAM(data.session_start_time, data.session_end_time) === 'AMPM') {
              session_date = data.session_date;
            }
            else if (checkPMAM(data.session_start_time, data.session_end_time) === 'AMAM') {
              if (checkDistance(data.session_start_time, data.session_end_time)) {
                session_date = dayjs(data.session_date).add(1, 'day').format('YYYY-MM-DD');
              }
              else {
                session_date = data.session_date;
              }
            }
            else if (checkPMAM(data.session_start_time, data.session_end_time) === 'PMPM') {
              if (checkDistance(data.session_start_time, data.session_end_time)) {
                session_date = dayjs(data.session_date).add(1, 'day').format('YYYY-MM-DD');
              }
              else {
                session_date = data.session_date;
              }
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'StartInside') {
              StartInside = [...StartInside, { id: data.id, session_end_time: props.startTime }];
              overlapDetect = true;
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EndInside') {
              EndInside = [...EndInside, { id: data.id, session_start_time: props.endTime }];
              overlapDetect = true;
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'InsideStartTouching') {
              InsideStartTouching = [...InsideStartTouching, { id: data.id, session_start_time: props.endTime }];
              overlapDetect = true;
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'InsideEndTouching') {
              InsideEndTouching = [...InsideEndTouching, { id: data.id, session_end_time: props.startTime }];
              overlapDetect = true;
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EnclosingStartTouching') {
              EnclosingStartTouching = [...EnclosingStartTouching, data.id];
              overlapDetect = true;
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EnclosingEndTouching') {
              EnclosingEndTouching = [...EnclosingEndTouching, data.id];
              overlapDetect = true;
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'Enclosing') {
              Enclosing = [...Enclosing, data.id];
              overlapDetect = true;
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'Inside') {
              Inside = [...Inside, { id: data.id, session_end_time: props.startTime }];

              if (session_date <= dayjs(props.timesheetEndDate).format('YYYY-MM-DD')) {

                AddInside = [...AddInside,
                {
                  activity: data.activity,
                  break_end_time: data.break_end_time,
                  break_start_time: data.break_start_time,
                  break_time: 0,
                  customer: data.customer,
                  duration: data.duration,
                  employee: data.employee,
                  end_time: data.end_time,
                  entry_number2: data.entry_number2,
                  leave_note: data.leave_note,
                  leave_status: data.leave_status,
                  notes: data.notes,
                  owner: data.owner,
                  session_date: session_date,
                  session_end_time: data.session_end_time,
                  session_start_time: props.endTime,
                  session_type: data.session_type,
                  timesheet: data.timesheet,
                  type: data.type,
                  status: data.status,
                }];
                overlapDetect = true;
              }
            }
            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'ExactMatch') {
              ExactMatch = [...ExactMatch, data.id];
            }
          })

          if (overlapDetect) {
            multipleSession = window.confirm("Other overlapping sessions will also be modified. Are you sure?");
            if (multipleSession === false) {
              setLoading(false);
              return
            }
          }

          if (ExactMatch.length > 0) {
            try {
              await directus.deleteItems('sessions', ExactMatch);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (StartInside.length > 0) {
            try {
              await directus.updateItems('sessions', StartInside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (EndInside.length > 0) {
            try {
              await directus.updateItems('sessions', EndInside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (InsideStartTouching.length > 0) {
            try {
              await directus.updateItems('sessions', InsideStartTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (InsideEndTouching.length > 0) {
            try {
              await directus.updateItems('sessions', InsideEndTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (EnclosingStartTouching.length > 0) {
            try {
              await directus.deleteItems('sessions', EnclosingStartTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (EnclosingEndTouching.length > 0) {
            try {
              await directus.deleteItems('sessions', EnclosingEndTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (Enclosing.length > 0) {
            try {
              await directus.deleteItems('sessions', Enclosing);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (Inside.length > 0) {
            try {
              await directus.updateItems('sessions', Inside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (AddInside.length > 0) {
            try {
              await directus.createItems('sessions', AddInside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }
        }
      }

      if (props.selectedDate) {
        editData = {
          session_date: dayjs(props.selectedDate).format('YYYY-MM-DD'),
        }
      }
      if (props.session.session_type.show_activity && props.activity) {
        editData = {
          ...editData,
          activity: props.activity.id,
        };
      }
      if (props.session.session_type.show_customer && props.customer) {
        editData = {
          ...editData,
          customer: props.customer.id,
        };
      }

      if (props.session.session_type.public_holiday || props.session.session_type.full_day)
        if (props.publicHoliday && !props.fullDay) {
          if ((props.startTime !== 'Invalid date' && props.endTime !== 'Invalid date') || (props.startTime !== null && props.endTime !== null)) {
            editData = {
              ...editData,
              session_start_time: props.startTime !== 'Invalid date' ? props.startTime : null,
              session_end_time: props.endTime !== 'Invalid date' ? props.endTime : null,
              break_start_time: props.breakStartTime !== 'Invalid date' ? props.breakStartTime : null,
              break_end_time: props.breakEndTime !== 'Invalid date' ? props.breakEndTime : null,
              duration: null,
            };
          }
        }
        else {
          editData = {
            ...editData,
            duration: props.session.session_type.default_duration,
            session_start_time: null,
            session_end_time: null,
            break_start_time: null,
            break_end_time: null,
          }
        }

      if (props.session.session_type.show_break_time) {
        editData = {
          ...editData,
          break_time: props.sessionBreakTime,
        };
      }

      if (props.session.session_type.show_break_start_end_time) {
        editData = {
          ...editData,
          break_start_time: props.breakStartTime !== 'Invalid date' ? props.breakStartTime : null,
          break_end_time: props.breakEndTime !== 'Invalid date' ? props.breakEndTime : null,
        };
      }

      if (props.session.session_type.show_start_end_time) {
        editData = {
          ...editData,
          session_start_time: props.startTime !== 'Invalid date' ? props.startTime : null,
          session_end_time: props.endTime !== 'Invalid date' ? props.endTime : null,
        };
      }

      if (props.session.session_type.show_duration || props.session.session_type.show_duration_admin_only) {
        editData = {
          ...editData,
          duration: parseTimeSpan(props.sessionDuration),
        };
      }

      if (props.sessionDuration !== '' && (props.startTime !== 'Invalid date' || props.endTime !== 'Invalid date')) {
        editData = {
          ...editData,
          session_start_time: props.startTime,
          session_end_time: props.endTime,
          duration: '',
        };
      }

      if (props.sessionNotes !== null || props.sessionNotes !== undefined) {
        editData = {
          ...editData,
          notes: props.sessionNotes,
        }
      }

      if (editData) {
        // ================================== Edit Session ===================================//
        try {
          await directus.updateItem('sessions', props.sessionID, editData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let addMaterial = [];
      let editMaterial = [];
      let delMaterial = [];

      //   //==========CRUD Material==========//  
      props.allmaterialData.map((data) => {
        if (data.value === 3) {
          delMaterial = [...delMaterial, data.resourceDataId];
        }
        if (data.value === 1) {
          editMaterial = [...editMaterial, { id: data.resourceDataId, session: props.sessionID, resource: data.id, type: 'chemical', quantity: data.quantity }];
        }
        if (data.value === 2 && data.name) {
          addMaterial = [...addMaterial, { session: props.sessionID, resource: data.id, type: 'chemical', quantity: data.quantity }];
        }
        return null;
      })

      if (delMaterial.length > 0) {
        try {
          await directus.deleteItems('sessions_resources', delMaterial);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      if (editMaterial.length > 0) {
        try {
          await directus.updateItems('sessions_resources', editMaterial);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      if (addMaterial.length > 0) {
        try {
          await directus.createItems('sessions_resources', addMaterial);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      //==========CRUD Equipment==========//
      let addEquipment = [];
      let editEquipment = [];
      let delEquipment = [];
      props.allEquipmentData.map((data) => {
        if (data.value === 3) {
          delEquipment = [...delEquipment, data.resourceDataId];
        }
        if (data.value === 1) {
          editEquipment = [...editEquipment, { id: data.resourceDataId, session: props.sessionID, resource: data.id, type: 'equipment', quantity: 0 }];
        }
        if (data.value === 2 && data.name) {
          addEquipment = [...addEquipment, { session: props.sessionID, resource: data.id, type: 'equipment', quantity: 0 }];
        }
        return null;
      })

      if (delEquipment.length > 0) {
        try {
          await directus.deleteItems('sessions_resources', delEquipment);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      if (editEquipment.length > 0) {
        try {
          await directus.updateItems('sessions_resources', editEquipment);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      if (addEquipment.length > 0) {
        try {
          await directus.createItems('sessions_resources', addEquipment);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let OptionsData = [];
      let newData = [];
      let deleteData = [];
      props.optionsList.map((options) => {
        if (options.id.toString().length < 10) {
          if (options.type !== "4" && options.type !== "3" && options.type !== "7") {
            newData = [...newData,
            {
              session: props.sessionID,
              status: 'published',
              session_option: options.session_option.id,
              value: options.value === null ? options.session_option.input_type === 'checkbox' ? 'false' : options.session_option.input_type === 'none' ? 'null' : 0 : options.value
            }];
          }
        }
        else {
          if (options.type !== "4" && options.type !== "3" && options.type !== "7") {
            OptionsData = [...OptionsData,
            {
              id: options.id,
              session: props.sessionID,
              status: 'published',
              session_option: options.session_option.id,
              value: options.value === null ? options.session_option.input_type === 'checkbox' ? 'false' : options.session_option.input_type === 'none' ? 'null' : 0 : options.value
            }];
          }
          else {
            deleteData = [...deleteData, options.id];
          }
        }
      })

      if (newData.length > 0) {
        try {
          await directus.createItem("sessions_session_options", newData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
      if (OptionsData.length > 0) {
        try {
          await directus.updateItems('sessions_session_options', OptionsData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
      if (deleteData.length > 0) {
        try {
          await directus.deleteItems("sessions_session_options", deleteData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      EventEmitter.emit('contentWidth', {
        data: window.DashboardGlobal
      })

      EventEmitter.emit('dashboardDrawer', {
        text: 5,
        session: props.session,
        timesheets: props.timesheets,
      })
    }
    else {
      setOpen(false);
      //================================== Delete Session ===================================//
      try {
        await directus.deleteItem('sessions', props.sessionID);

        // After deleting the session, move the sidebar view to the Add Timesheet Entry view
        window.DashboardGlobal = true;
        EventEmitter.emit('contentWidth', {
          data: window.DashboardGlobal
        })

        EventEmitter.emit('dashboardDrawer', {
          text: 6,
          timesheets: props.timesheets,
        })
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    saveEditSessionDrawer(true);
    setLoading(false);
  }

  return (
    <>
      <DeleteButton
        id="submit_for_approval_btn"
        size="small"
        fullWidth
        onClick={handleClickOpen}
        disabled={loading}
      >
        <Typography className={classes.fontstyle6}>Delete</Typography>
      </DeleteButton>

      <SaveButton
        id="submit_for_approval_btn"
        size="small"
        fullWidth
        onClick={() => SessionUpdateDelete('edit')}
        disabled={disabledCheck}
        loading={loading}
      >
        <Typography className={classes.fontstyle6}>Save Session</Typography>
      </SaveButton>

      <Dialog
        open={open}
        onClose={handleClose}
        id={`delete_session_dialog_`}
      >
        <DialogTitle id="alert-dialog-title"><Typography className={classes.fontstyle7}> {"Are you sure you want to delete this session?"}</Typography></DialogTitle>
        <DialogActions>
          <Button id={`cancel_delete_session_`} onClick={handleClose} color="primary">
            No
          </Button>
          <Button id={`save_delete_session_`} onClick={() => SessionUpdateDelete('delete')} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}