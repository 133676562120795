import React from 'react';
import {
  Button,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import directus from '../../../services/directus';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../views/Components/ErrorMessage';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import EventEmitter from 'src/utils/EventEmitter';
import {
  useStylesEditSession,
  CancelButton,
  SaveButton,
} from './style.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { TimeRange, TimeRelation } from 'datetime-period';
import { Start } from '@mui/icons-material';

export default function SaveAddSession(props) {

  const classes = useStylesEditSession();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(0);
  var disabledCheck = false;
  var multipleSession = '';

  if (!props.session.data) {
    var disabledCheck = true;
  }
  else if (props.session.data.show_customer && !props.customer) {
    disabledCheck = true;
  }
  else if (props.session.data.show_activity && !props.activity) {
    disabledCheck = true;
  }
  else {
    if (!props.session.checkbox_full_day && props.session.checkbox_public_holiday) {
      if (props.session.data.show_start_end_time) {
        disabledCheck = props.startTime === "Invalid date" || props.endTime === "Invalid date";
      }

      if (props.session.data.show_duration || props.session.data.show_duration_admin_only){
        disabledCheck = props.sessionDuration === null || parseTimeSpan(props.sessionDuration) === 0;
      }

      if (props.session.data.show_start_end_time && (props.session.data.show_duration || props.session.data.show_duration_admin_only)) {
        disabledCheck = (props.startTime === "Invalid date" || props.endTime === "Invalid date") && (props.sessionDuration === null || parseTimeSpan(props.sessionDuration) === 0);
      }
    }
    else {
      disabledCheck = false;
    }
  }

  const saveAddSessionDrawer = (text) => {
    EventEmitter.emit('saveAddSessionDrawer', {
      text: text,
      tickCustomer: props.tickCustomer,
      tickActivity: props.tickActivity,
      info: 'addsession'
    })
  }

  const dashboardDrawer = (data ) => {
    window.DashboardGlobal = false;
    setOpenDrawer(0);

    EventEmitter.emit('contentWidth', {
      data: window.DashboardGlobal
    })

    EventEmitter.emit('dashboardDrawer', {
      text: data,
    })

  }

  function parseTimeSpan(timeString) {
    if (timeString !== null) {
      let parts = timeString ? timeString.split("h") : '';
      let parts2 = timeString ? timeString.split("m") : '';
      if (parts.length > 1 || parts2.length > 1) {
        if (parts.length === 1) {
          return (parseInt(parts[0].slice(0, -1), 10))
        }
        else {
          return (parseInt(parts[0], 10) * 60) + (parseInt(parts[1].slice(0, -1) | 0, 10))
        }
      }
      else {
        var decimalTimeString = timeString;
        var n = new Date(0, 0);
        n.setMinutes(+decimalTimeString * 60);
        return ((moment(n).hour() * 60) + moment(n).minute());
      }
    }
    else {
      return 0;
    }
  }

  function getAMPM(time) {

    const hours = time?time.slice(0, 2):'00:00::00';
    if (hours >= 12) {
      return 'PM';
    }
    else {
      return 'AM';
    }
  }

  function checkPMAM(startTime, endTime) {
    var checkStartTime = getAMPM(startTime);
    var checkEndTime = getAMPM(endTime);

    if (checkStartTime === 'PM' && checkEndTime === 'AM') {
      return 'PMAM';
    }
    else if (checkStartTime === 'AM' && checkEndTime === 'AM') {
      return 'AMAM';
    }
    else if (checkStartTime === 'PM' && checkEndTime === 'PM') {
      return 'PMPM';
    }
    else {
      return 'AMPM';
    }
  }

  function checkDistance(start, end) {
    if (end != null) {
      var diff;
      if (start > end) {
        diff = moment.duration(moment(moment('1991-01-02T' + end).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + start).format('YYYY-MM-DDTHH:mm:00')));
      }
      else {
        diff = moment.duration(moment(moment('1991-01-01T' + end).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + start).format('YYYY-MM-DDTHH:mm:00')));
      }
      if (diff.hours() * 60 + diff.minutes() > 720) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  const SessionAdd = async () => {
    setLoading(true);
    let editTimesheet = [];

    if(props.timesheetStatus === 'approved' && props.enableLateSessions){
      try {
        editTimesheet={ 
          status: 'awaiting_approval',
          extra: true
        };

        await directus.updateItem('timesheets', props.timesheetId, editTimesheet);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    
    var SessionsFilter = [];
    let addData = [];
    var StartInside = [];
    var EndInside = [];
    var InsideStartTouching = [];
    var InsideEndTouching = [];
    var Enclosing = [];
    var EnclosingStartTouching = [];
    var EnclosingEndTouching = [];
    var Inside = [];
    var AddInside = [];
    var ExactMatch = [];
    var session_date = '';
    var overlapDetect = false;
    var timeRangeB = '';

    // ============Overlap========================//
    if (props.session.data.show_start_end_time) {
      SessionsFilter = await directus.getItems('sessions', {
        fields: '*',
        filter:
        {
          employee: { eq: props.employeeId },
          session_date: { in: [dayjs(props.selectedDate).format('YYYY-MM-DD'), dayjs(props.selectedDate).add(1, 'day').format('YYYY-MM-DD'), dayjs(props.selectedDate).add(-1, 'day').format('YYYY-MM-DD')] },
        },
        limit: -1,
      });

      if (checkDistance(props.startTime, props.endTime)) {
        multipleSession = window.confirm("Session is more than 12 hours long. Are you sure this is correct?");
        if (multipleSession === false) {
          setLoading(false);
          return
        }
      }

      if (checkPMAM(props.startTime, props.endTime) === 'PMAM') {
        timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).add(1, 'day').format('YYYY-MM-DD')) + ' ' + props.endTime));
      }
      else if (checkPMAM(props.startTime, props.endTime) === 'AMPM') {
        timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).format('YYYY-MM-DD')) + ' ' + props.endTime));
      }
      else if (checkPMAM(props.startTime, props.endTime) === 'AMAM') {
        if (checkDistance(props.startTime, props.endTime)) {
          timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).add(1, 'day').format('YYYY-MM-DD')) + ' ' + props.endTime));
        }
        else {
          timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).format('YYYY-MM-DD')) + ' ' + props.endTime));
        }
      }
      else if (checkPMAM(props.startTime, props.endTime) === 'PMPM') {
        if (checkDistance(props.startTime, props.endTime)) {
          timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).add(1, 'day').format('YYYY-MM-DD')) + ' ' + props.endTime));
        }
        else {
          timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).format('YYYY-MM-DD')) + ' ' + props.endTime));
        }
      }

      if (SessionsFilter.data.length > 0) {
        SessionsFilter.data.map((data, index) => {
          if (checkPMAM(data.session_start_time, data.session_end_time) === 'PMAM') {
            session_date = dayjs(data.session_date).add(1, 'day').format('YYYY-MM-DD');
          }
          else if (checkPMAM(data.session_start_time, data.session_end_time) === 'AMPM') {
            session_date = data.session_date;
          }
          else if (checkPMAM(data.session_start_time, data.session_end_time) === 'AMAM') {
            if (checkDistance(data.session_start_time, data.session_end_time)) {
              session_date = dayjs(data.session_date).add(1, 'day').format('YYYY-MM-DD');
            }
            else {
              session_date = data.session_date;
            }
          }
          else if (checkPMAM(data.session_start_time, data.session_end_time) === 'PMPM') {
            if (checkDistance(data.session_start_time, data.session_end_time)) {
              session_date = dayjs(data.session_date).add(1, 'day').format('YYYY-MM-DD');
            }
            else {
              session_date = data.session_date;
            }
          }

          if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'StartInside') {
            StartInside = [...StartInside, { id: data.id, session_end_time: props.startTime }];
            overlapDetect = true;
          }

          if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EndInside') {
            var date='';

            if(getAMPM(data.session_start_time) ==='PM' && getAMPM(data.session_end_time) ==='AM'){
              date= dayjs(data.session_date).add(1, 'day').format('YYYY-MM-DD');
            }
            else{
              date= data.session_date;
            }
            
            EndInside = [...EndInside, { id: data.id, session_date:date, session_start_time: props.endTime }];
            
            overlapDetect = true;
          }

          if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'InsideStartTouching') {
            InsideStartTouching = [...InsideStartTouching, { id: data.id, session_start_time: props.endTime }];
            overlapDetect = true;
          }

          if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'InsideEndTouching') {
            InsideEndTouching = [...InsideEndTouching, { id: data.id, session_end_time: props.startTime }];
            overlapDetect = true;
          }

          if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EnclosingStartTouching') {
            EnclosingStartTouching = [...EnclosingStartTouching, data.id];
            overlapDetect = true;
          }

          if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EnclosingEndTouching') {
            EnclosingEndTouching = [...EnclosingEndTouching, data.id];
            overlapDetect = true;
          }

          if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'Enclosing') {
            Enclosing = [...Enclosing, data.id];
            overlapDetect = true;
          }

          if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'Inside') {
            Inside = [...Inside, { id: data.id, session_end_time: props.startTime }];

            if (session_date <= dayjs(props.timesheetEndDate).format('YYYY-MM-DD')) {

              AddInside = [...AddInside,
              {
                activity: data.activity,
                break_end_time: data.break_end_time,
                break_start_time: data.break_start_time,
                break_time: 0,
                customer: data.customer,
                duration: data.duration,
                employee: data.employee,
                end_time: data.end_time,
                entry_number2: data.entry_number2,
                leave_note: data.leave_note,
                leave_status: data.leave_status,
                notes: data.notes,
                owner: data.owner,
                session_date: session_date,
                session_end_time: data.session_end_time,
                session_start_time: props.endTime,
                session_type: data.session_type,
                timesheet: data.timesheet,
                type: data.type,
                status: data.status,
              }];
              overlapDetect = true;
            }
          }

          if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'ExactMatch') {
            ExactMatch = [...ExactMatch, data.id];
            overlapDetect = true;
          }
        })

        if (overlapDetect) {
          multipleSession = window.confirm("Other overlapping sessions will also be modified. Are you sure?");
          if (multipleSession === false) {
            setLoading(false);
            return
          }
        }

        if (ExactMatch.length > 0) {
          try {
            await directus.deleteItems('sessions', ExactMatch);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }

        if (StartInside.length > 0) {
          try {
            await directus.updateItems('sessions', StartInside);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }

        if (EndInside.length > 0) {
          try {
            await directus.updateItems('sessions', EndInside);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }

        if (InsideStartTouching.length > 0) {
          try {
            await directus.updateItems('sessions', InsideStartTouching);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }

        if (InsideEndTouching.length > 0) {
          try {
            await directus.updateItems('sessions', InsideEndTouching);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }

        if (EnclosingStartTouching.length > 0) {
          try {
            await directus.deleteItems('sessions', EnclosingStartTouching);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }

        if (EnclosingEndTouching.length > 0) {
          try {
            await directus.deleteItems('sessions', EnclosingEndTouching);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }

        if (Enclosing.length > 0) {
          try {
            await directus.deleteItems('sessions', Enclosing);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }

        if (Inside.length > 0) {
          try {
            await directus.updateItems('sessions', Inside);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }

        if (AddInside.length > 0) {
          try {
            await directus.createItems('sessions', AddInside);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }
      }
    }

    addData = {
      timesheet: props.timesheetId,
      session_type: props.type.data.id,
      employee: props.employeeId,
      session_date: dayjs(props.selectedDate).format('YYYY-MM-DD'),
      notes: props.sessionNotes,
    }

    if((props.timesheetStatus === 'approved' && props.enableLateSessions) || props.timesheetExtra){
      addData = {
        ...addData,
        extra: true,
      };
    }

    if (props.session.data.show_activity && props.activity) {
      addData = {
        ...addData,
        activity: props.activity.id,
      };
    }
    if (props.session.data.show_customer && props.customer) {
      addData = {
        ...addData,
        customer: props.customer.id,
      };
    }

    if ((props.session.checkbox_full_day || !props.session.checkbox_public_holiday)) {
      if ((props.startTime === "Invalid date" && props.endTime === "Invalid date") || (props.startTime === null && props.endTime === null)) {
        addData = {
          ...addData,
          duration: props.session.data.default_duration,
          session_start_time: null,
          session_end_time: null,
          break_start_time: null,
          break_end_time: null,
        }
      }
    }

    if (props.session.data.show_break_time && props.sessionBreakTime) {
      addData = {
        ...addData,
        break_time: props.sessionBreakTime,
      };
    }

    if (props.session.data.show_break_start_end_time && (props.breakStartTime !== null || props.breakEndTime !== null) && (props.breakStartTime !== 'Invalid date' || props.breakEndTime !== 'Invalid date')) {
      addData = {
        ...addData,
        break_start_time: props.breakStartTime !== 'Invalid date' ? props.breakStartTime : null,
        break_end_time: props.breakEndTime !== 'Invalid date' ? props.breakEndTime : null,
      };
    }

      if (props.session.data.show_start_end_time) {
        addData = {
          ...addData,
          session_start_time: props.startTime !== 'Invalid date' ? props.startTime : null,
          session_end_time: props.endTime !== 'Invalid date' ? props.endTime : null,
        };
      }

      if (props.session.data.show_duration || props.session.data.show_duration_admin_only) {
        addData = {
          ...addData,
          duration: parseTimeSpan(props.sessionDuration),
        };
      }

      if(props.sessionDuration !== '' && (props.startTime !== 'Invalid date' || props.endTime !== 'Invalid date' )){
        addData = {
          ...addData,
          session_start_time: props.startTime,
          session_end_time: props.endTime,
          duration:'',
        };
      }
      
      var createSession;

    if (addData) {
      try {
        createSession = await directus.createItems('sessions', addData);
        
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    createResources(createSession.data.id);
    uploadFileDescription(createSession.data.id);
    createSessionOptions(createSession.data.id);
    saveAddSessionDrawer(true);
    setLoading(false);
  }

  const createResources = async (sessionID) => {
    //==========Material==========//
    let addMaterial = [];

    if (props.allmaterialData) {
      props.allmaterialData.map((data) => {
        if (data.value === 2 && data.name) {
          addMaterial = [...addMaterial, { session: sessionID, resource: data.id, type: 'chemical', quantity: data.quantity }];
        }
      })

      if (addMaterial.length > 0) {
        try {
          await directus.createItems('sessions_resources', addMaterial);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }

    //==========Equipment==========//
    let addEquipment = [];
    if (props.allEquipmentData) {
      props.allEquipmentData.map((data) => {
        if (data.value === 2 && data.name) {
          addEquipment = [...addEquipment, { session: sessionID, resource: data.id, type: 'equipment', quantity: 0 }];
        }
      })

      if (addEquipment.length > 0) {
        try {
          await directus.createItems('sessions_resources', addEquipment);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }
  }

  const uploadFileDescription = async (sessionID) => {
    if (props.fileUpload) {
      if (props.fileUpload.length > 0) {
        const form = document.querySelector("#my-form");
        const data = new FormData(form);

        let uploadFile = await directus.uploadFiles(data, onUploadProgress);
        try {
          await directus.createItem('sessions_directus_files', {
            session: sessionID,
            directus_file: uploadFile.data.id
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }
  }

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }


  const createSessionOptions = async (sessionID) => {

    let OptionsData = [];

    props.optionsList.map((options) => {
      if (options.type !== "4" && options.type !== "3" && options.type !== "7") {
        OptionsData = [...OptionsData,
        {
          session: sessionID,
          status: 'published',
          session_option: options.id,
          value: options.value === null ? options.data.input_type === 'checkbox' ? 'false' : 0 : options.value
        }];
      }
    })

    if (OptionsData.length > 0) {
      try {
        await directus.createItems("sessions_session_options", OptionsData);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
  }

  return (
    <>
      <CancelButton
        id="submit_for_approval_btn"
        size="small"
        fullWidth
        onClick={() => dashboardDrawer(7)}
        disabled={loading}
      >
        <Typography className={classes.fontstyle6}>Cancel</Typography>
      </CancelButton>

      <SaveButton
        id="submit_for_approval_btn"
        size="small"
        fullWidth
        onClick={() => SessionAdd()}
        disabled={disabledCheck || loading}
        loading={loading}
      >
        <Typography className={classes.fontstyle6}>Add Session</Typography>
      </SaveButton>
    </>
  );
}
