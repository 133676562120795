import React, { useState, useEffect } from 'react';
import EventEmitter from 'src/utils/EventEmitter';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Grid
} from '@material-ui/core';
import {
  SubmitButton,
  ApproveButton,
  RejectButton,
  CancelButton,
  TextFieldCustom,
  TextFieldNotes
} from './style.js';
import { useNavigate } from 'react-router-dom';
import directus from '../../services/directus';
import { SupervisorPermission, AdminPermission } from 'src/utils/Permission';
import moment from 'moment';
import useStateRef from "react-usestateref";
import LockIcon from '@material-ui/icons/Lock';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';

let calculate;
const directusEmployeeData = DirectusEmployee();

export default function ApproveRejectButton(props) {
  /* eslint-disable no-unused-vars*/
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [openApprove, setOpenApprove] = React.useState(false);
  const [approveText, setApproveText] = React.useState('');
  const [note, setNote] = React.useState('');

  const [approveCount, setApproveCount, approveCountRef] = useStateRef('');
  const [checkedCount, setCheckedCount, checkedCountRef] = useStateRef('');
  const [openSubmit, setOpenSubmit] = React.useState(false);

  const [noteSubmit, setNoteSubmit] = React.useState('');
  const [timesheetEvent, setTimesheetEvent, timesheetEventRef] = useStateRef('');
  const [approvalLockData, setApprovalLockData, ApprovalLockDataRef] = useStateRef('');
  const { enqueueSnackbar } = useSnackbar();
  const [weekMinutes, setWeekMinutes] = React.useState(null);

  const [duration, setDuration] = React.useState(time_convert_Duration(0));
  const [durationClaim, setDurationClaim] = React.useState(time_convert_Duration(0));

  var configuration = JSON.parse(window.localStorage.getItem('configuration')).full_week_minutes;
  var config_ts = JSON.parse(window.localStorage.getItem('config_ts'));

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      firstLoad();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenApprove = () => {
    let checkValue = [];
    let approveErrorValue = 0;
    let checkedErrorValue = 0;
    let sessionPending = 0;
    let isNoCheck = false;

    props.sessionData.map((data) => {
      //if no checked condition (only click approve button)
      if (data.select && data.status === 'pending') {
        if (data.session_start_time !== null && data.session_start_time !== data.session_end_time) {
          checkValue.push({ id: data.id, status: 'approved' });
        }
        else if (data.duration !== 0) {
          checkValue.push({ id: data.id, status: 'approved' });
        }
        else {
          approveErrorValue = approveErrorValue + 1;
        }
      }

      if (data.status === 'pending') {
        sessionPending = sessionPending + 1;
      }

      return null;
    })

    //if checked condition
    if (checkValue.length === 0) {
      isNoCheck = true;
      //eslint-disable-next-line array-callback-return
      props.sessionData.map((data) => {

        if (data.status === 'pending') {
          if (data.session_start_time !== null && data.session_start_time !== data.session_end_time) {
            checkValue.push({ id: data.id, status: 'approved' });
          }
          else if (data.duration !== 0) {
            checkValue.push({ id: data.id, status: 'approved' });
          }
          else {
            checkedErrorValue = checkedErrorValue + 1;
          }
        }
      })
    }

    if(props.overlapCheck > 0){
      setApproveText('This timesheet has an overlap sessions. Please change the session before approving the timesheet.');
    }
    else if (props.sessionData.length === 0) {
      setApproveText('This timesheet has no sessions. Please add a session before approving the timesheet.');
    }
    else if (approveErrorValue > 0) {
      setApproveCount(approveErrorValue);
      setApproveText("There is " + approveErrorValue + " session(s) with a duration of 0 hours. Please fix this duration first and try again.");
    }
    else if (checkedErrorValue > 0) {
      setCheckedCount(checkedErrorValue);
      setApproveText("There is " + checkedErrorValue + " session(s) with a duration of 0 hours. Please fix this duration first and try again.");
    }
    else if (isNoCheck === true) {  
      setApproveText('No sessions selected, all sessions will be approved, are you sure?');
    }
    else if (checkValue.length === sessionPending) {
      setApproveCount(0);
      setCheckedCount(0);
      setApproveText('All sessions selected, are you sure?');
    }
    else if (checkValue.length > 0) {
      setApproveCount(0);
      setCheckedCount(0);
      setApproveText(checkValue.length + ' sessions selected, are you sure?');
    }
    else {
      setApproveText('There is no process in this sessions');
    }

    setOpenApprove(true);
  };

  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  function nextTimesheet() {
    navigate('/approvals/' + props.right.id);
    props.loadTimesheets(props.right.id);
    props.changeButtonLoading(false);
  }

  const approveButton = async () => { 
    handleCloseApprove();
    EventEmitter.emit('topBar', { text: 'ApprovalsReview' });
    props.changeButtonLoading(true);
    props.setLoading(true);
    let checkValue = [];
    let allSession = [];

    let timesheetId = window.location.pathname.split('/').pop();

    props.sessionData.map((data) => {
      if (data.select && data.status === 'pending') {
        checkValue.push(data.id);
      }
      return null;
    })

    if (checkValue.length === 0) {
      try {
        var SessionResult = await directus.getItems('sessions', {
          fields: 'id',
          filter: {
            timesheet: { in: timesheetId },
            status: 'pending'
          }
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      if (SessionResult.data.length !== 0) {
        SessionResult.data.map((data) => {
          allSession.push(data.id);
          return null;
        })

        try {
          await directus.createItems('approvals_record', [{ approved_by:directusEmployeeData.id, timesheet: timesheetId }]);

          await directus.api.patch('/custom/sessions/status', {
            sessions: allSession,
            status: "approved"
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      try {
        //eslint-disable-next-line no-redeclare
        var SessionResult = await directus.getItems('sessions', {
          fields: 'id',
          filter: {
            employee: { eq: props.employeeIdChosen },
            timesheet: { eq: timesheetId },
            status: 'pending'
          }
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      if (SessionResult.data.length === 0) {
        try {
          await directus.updateItem('timesheets', timesheetId, {
            status: 'approved',
          }, {
            fields: 'id',
          });
          props.right ? nextTimesheet() : navigate('/approvals');
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
      else {
        props.loadTimesheets();
        props.changeButtonLoading(false);
      }
    }
    else {
      try {
        
        await directus.api.patch(`/custom/sessions/status`,{ 
          sessions: checkValue,
          status: "approved"
        },
        );
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      try {
        //eslint-disable-next-line no-redeclare
        var SessionResult = await directus.getItems('sessions', {
          fields: 'id',
          filter: {
            employee: { eq: props.employeeIdChosen },
            timesheet: { eq: timesheetId },
            status: 'pending'
          }
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      if (SessionResult.data.length === 0) {
        try {
          await directus.updateItem('timesheets', timesheetId, {
            status: 'approved',
          },{
            fields: 'id',
          });
          
          props.right ? nextTimesheet() : navigate('/approvals');
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
      else {
        props.loadTimesheets();
        props.changeButtonLoading(false);
      }
    }
  }

  const rejectButton = async () => {
    handleClose();
    EventEmitter.emit('topBar', { text: 'ApprovalsReview' });
    props.changeButtonLoading(true);
    props.setLoading(true);
    let checkValue = [];

    props.sessionData.map((data) => {
      if (data.select && data.status === 'pending') {
        checkValue.push({ id: data.id, status: 'rejected' });
      }
      return null;
    })

    if (checkValue.length !== 0) {
      try {  
        await directus.updateItems('sessions', checkValue);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    let timesheetId = window.location.pathname.split('/').pop();
    try {
      await directus.updateItem('timesheets', timesheetId, {
        status: 'rejected',
        reviewer_notes: note,
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    props.right ? nextTimesheet() : navigate('/approvals');
  }

  const unApproveButton = async () => {
    let editTimesheet = null;

    handleClose();
    props.changeButtonLoading(true);
    props.setLoading(true);
    let checkValue = [];

    let timesheetId = window.location.pathname.split('/').pop();
    try {
      var SessionResult = await directus.getItems('sessions', {
        fields: '*',
        filter: {
          employee: { eq: props.employeeIdChosen },
          timesheet: { eq: timesheetId },
          status: 'approved',
        }
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (SessionResult.data.length > 0) {
      //eslint-disable-next-line array-callback-return
      SessionResult.data.map((data) => {
        checkValue.push({ id: data.id, status: 'pending' });
      })

      try {
        await directus.updateItems('sessions', checkValue);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    if (config_ts.enable_late_sessions && props.timesheets.extra) 
    {
      editTimesheet = {...editTimesheet, extra:false};
    }
    
      editTimesheet = {...editTimesheet, status: 'awaiting_approval',};

    try {
      await directus.updateItem('timesheets', timesheetId, editTimesheet);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    props.loadTimesheets();
    props.changeButtonLoading(false);
  }
  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === 'notes') {
      setNote(value);
    }
    else if (name === 'notesSubmit') {
      setNoteSubmit(value);
    }
    else if (name === 'duration') {
      setDuration(value);
    }
    else if (name === 'duration_claim') {
      setDurationClaim(value);
    }
  }

  const handleClickSubmit = async () => {
    if (props.enableTimesheetBankHours) {
      setWeekMinutes(configuration);
      setDuration(time_convert_Duration(props.timesheets.banked_minutes));
      setDurationClaim(time_convert_Duration(props.timesheets.claim_banked_minutes));
      if(props.timesheets.employee.rdo_hours_enable || props.timesheets.employee.banked_hours_enable){
        setOpenSubmit(true);
      }   
      else{
        submitApprovalButton();
      } 
    };
  }

  const handleCloseSubmit = () => {
    setOpenSubmit(false);
  };

  const checkTimeValid = (data) => {
    if (moment(data).isValid()) {
      return true;
    }
    else {
      setDuration('');
    }
  }


  const checkClaimTimeValid = (data) => {
    if (moment(data).isValid()) {
      return true;
    }
    else {
      setDurationClaim('');
    }
  }


  function time_convert() {
    if (duration !== '' || duration !== "0" || duration !== null) {
      let parts = duration.split("h");
      let parts2 = duration.split("m");
      if (parts.length > 1 || parts2.length > 1) {
        if (parts.length === 1) {
          if (checkTimeValid(parseInt(parts[0].slice(0, -1), 10))) {
            setDuration(parseInt(parts[0].slice(0, -1), 10) + "m");
          }
        }
        else {
          if (checkTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1) | 0, 10))))) {
            setDuration((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1) | 0, 10) > 60 ? 0 + "m" : parseInt(parts[1].slice(0, -1) | 0, 10) + "m"));
          }
        }
      }
      else {
        var decimalTimeString = duration;
        var n = new Date(0, 0);
        n.setMinutes(+decimalTimeString * 60);
        if (checkTimeValid(n)) {
          setDuration((moment(n).hour() + "h ") + moment(n).minute() + "m");
        }
      }
    }
  }

  function time_convert_claim() {
    if (durationClaim !== '' || durationClaim !== "0" || durationClaim !== null) {
      let parts = durationClaim.split("h");
      let parts2 = durationClaim.split("m");
      if (parts.length > 1 || parts2.length > 1) {
        if (parts.length === 1) {
          if (checkClaimTimeValid(parseInt(parts[0].slice(0, -1), 10))) {
            setDurationClaim(parseInt(parts[0].slice(0, -1), 10) + "m");
          }
        }
        else {
          if (checkClaimTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1) | 0, 10))))) {
            setDurationClaim((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1) | 0, 10) > 60 ? 0 + "m" : parseInt(parts[1].slice(0, -1) | 0, 10) + "m"));
          }
        }
      }
      else {
        var decimalTimeString = durationClaim;
        var n = new Date(0, 0);
        n.setMinutes(+decimalTimeString * 60);
        if (checkClaimTimeValid(n)) {
          setDurationClaim((moment(n).hour() + "h ") + moment(n).minute() + "m");
        }
      }
    }
  }

  function time_convert_Duration(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;

    return hours + "h " + minutes + "m";
  }

  function parseTimeSpan(timeString) {
    let parts = timeString.split("h");
    let parts2 = timeString.split("m");
    if (parts.length > 1 || parts2.length > 1) {
      if (parts.length === 1) {
        return (parseInt(parts[0].slice(0, -1), 10))
      }
      else {
        return (parseInt(parts[0], 10) * 60) + (parseInt(parts[1].slice(0, -1) | 0, 10))
      }
    }
    else {
      var decimalTimeString = timeString;
      var n = new Date(0, 0);
      n.setMinutes(+decimalTimeString * 60);
      return ((moment(n).hour() * 60) + moment(n).minute());
    }
  }

  const submitApprovalButton = async () => {
    props.changeButtonLoading(true);
    let timesheetId = window.location.pathname.split('/').pop();

    let dataSubmit;

    if (duration !== '' || duration !== "0" || duration !== null) {
      dataSubmit = {
        status: 'awaiting_approval',
        submission_notes: noteSubmit,
        banked_minutes: parseTimeSpan(duration)
      }
    }
    else {
      dataSubmit = {
        status: 'awaiting_approval',
        submission_notes: noteSubmit,
      }
    }

    if (durationClaim !== '' || durationClaim !== "0" || durationClaim !== null) {
      dataSubmit = { ...dataSubmit, claim_banked_minutes: parseTimeSpan(durationClaim) };
    }

    try {
      await directus.updateItem('timesheets', timesheetId, dataSubmit);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    props.loadTimesheets();
    props.changeButtonLoading(false);
  }


  const firstLoad = async () => {
    let timesheetId = window.location.pathname.split('/').pop();
    try {
      var TimesheetEvent = await directus.getItems('timesheet_event', {
        fields: '*.*.*',
        filter: {
          'timesheet.id': { eq: timesheetId },
        },
        sort: 'created_on'
      });
      setTimesheetEvent(TimesheetEvent.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    try {
      var lockResult = await directus.getItems('approvals_lock', {
        fields: '*.*',
        filter: {
          start_date: { eq: props.timesheets.start_time },
        },

      })
      setApprovalLockData(lockResult.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

  }

  const isInvalid = note === '';

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {props.timesheets.status === 'pending' || props.timesheets.status === 'rejected' ?
            <>
              <div style={{ float: 'right', paddingTop: '10px', paddingBottom: '10px' }}>
                <SubmitButton
                  id="submit_for_approval_btn"
                  size="small"
                  variant="contained"
                  onClick={handleClickSubmit}
                  disabled={props.timesheets.employee.status === 'deleted'}
                >
                  <Typography variant="h6"> Submit for Approval </Typography>
                </SubmitButton>
              </div>
              <Dialog id="submit_for_approval_dialog" isopen={`${openSubmit}`} open={openSubmit} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
                <DialogContent>
                  <DialogContentText>
                    Timesheet submission details
                  </DialogContentText>
                  <Typography variant="h6">Notes</Typography>
                  <TextFieldNotes
                    size='small'
                    id="reject-note"
                    name="notesSubmit"
                    placeholder='(Optional) Leave a note'
                    multiline
                    fullWidth={true}
                    minRows={4}
                    maxRows={4}
                    value={noteSubmit}
                    onChange={handleInputChange}
                    variant="outlined"
                  />

                  {props.enableTimesheetBankHours ?
                    props.timesheets.employee.banked_hours_enable?
                      <div>
                        <br />
                        <TextFieldCustom
                          variant="outlined"
                          label={"Bank Extra Hours " + (calculate >= 0 ? "(max: " + time_convert_Duration(calculate) + ")" : '')}
                          name="duration"
                          placeholder="1h 30m"
                          sx={{ width: '45%' }}
                          value={duration}
                          onChange={handleInputChange}
                          onBlur={(e) => time_convert(e)}
                          size="small"
                        />
                        <br />
                        <br />
                        <TextFieldCustom
                          variant="outlined"
                          label={"Claim Banked Hours"}
                          name="duration_claim"
                          placeholder="1h 30m"
                          sx={{ width: '45%' }}
                          value={durationClaim}
                          onChange={handleInputChange}
                          onBlur={(e) => time_convert_claim(e)}
                          size="small"
                        />
                      </div>
                    :props.timesheets.employee.rdo_hours_enable?
                      <div>
                        <br />
                        <TextFieldCustom
                          variant="outlined"
                          label={"RDO Time Accrued"}
                          name="duration"
                          placeholder="1h 30m"
                          sx={{ width: '45%' }}
                          value={duration}
                          onChange={handleInputChange}
                          onBlur={(e) => time_convert(e)}
                          size="small"
                        />
                        <br />
                        <br />
                        <TextFieldCustom
                          variant="outlined"
                          label={"RDO Time Taken"}
                          name="duration_claim"
                          placeholder="1h 30m"
                          sx={{ width: '45%' }}
                          value={durationClaim}
                          onChange={handleInputChange}
                          onBlur={(e) => time_convert_claim(e)}
                          size="small"
                        />
                      </div>
                    :''
                  : ''}
                </DialogContent>
                <DialogActions>
                  <CancelButton
                    id="cancel_approval"
                    size="small"
                    variant="contained"
                    onClick={handleCloseSubmit}
                  >
                    <Typography variant="h6"> Cancel</Typography>
                  </CancelButton>
                  <SubmitButton
                    id="submit_approval"
                    size="small"
                    variant="contained"
                    onClick={submitApprovalButton}
                  >
                    <Typography variant="h6">Submit</Typography>
                  </SubmitButton>
                </DialogActions>
              </Dialog>
            </>
            : ''}

          {props.timesheets.status === 'awaiting_approval' ?
            <div style={{ float: 'right', paddingTop: '10px', paddingBottom: '10px', }}>
              <ApproveButton
                id="approve_session_btn"
                size="small"
                variant="contained"
                onClick={handleClickOpenApprove}
                disabled={props.timesheets.employee.status === 'deleted'}
              >
                <Typography variant="h6">Approve</Typography>
              </ApproveButton>
              <Dialog id="approve_dialog" isopen={`${openApprove}`} open={openApprove} onClose={handleCloseApprove} aria-labelledby="form-dialog-title" fullWidth={true}>
                <DialogContent>
                  <DialogContentText>
                    <span id="approve_text">{approveText}</span>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>

                  {approveCountRef.current > 0 || checkedCountRef.current > 0 || props.sessionData.length === 0 || props.overlapCheck > 0?
                    <span>
                      <CancelButton
                        id="cancel_approve_btn"
                        size="small"
                        variant="contained"
                        onClick={handleCloseApprove}
                      >
                        <Typography variant="h6">Cancel</Typography>
                      </CancelButton>
                    </span>
                    :
                    <span>
                      <CancelButton
                        id="cancel_approve_btn"
                        size="small"
                        variant="contained"
                        onClick={handleCloseApprove}
                      >
                        <Typography variant="h6">Cancel</Typography>
                      </CancelButton>
                      <ApproveButton
                        id="approve_btn"
                        size="small"
                        variant="contained"
                        onClick={approveButton}
                      >
                        <Typography variant="h6">Approve</Typography>
                      </ApproveButton>
                    </span>
                  }
                </DialogActions>
              </Dialog>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <RejectButton
                id="reject_session_btn"
                size="small"
                variant="contained"
                onClick={handleClickOpen}
                disabled={props.timesheets.employee.status === 'deleted'}
              >
                <Typography variant="h6">Reject</Typography>
              </RejectButton>

              <Dialog id="reject_dialog" isopen={`${open}`} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
                <DialogContent>
                  <DialogContentText>
                    Please enter the note here.
                  </DialogContentText>

                  <TextFieldNotes
                    size='small'
                    name="notes"
                    id="reject-note"
                    label="Notes"
                    multiline
                    fullWidth={true}
                    minRows={4}
                    maxRows={4}
                    value={note}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                </DialogContent>
                <DialogActions>
                  <CancelButton
                    id="cancel_reject_btn"
                    size="small"
                    variant="contained"
                    onClick={handleClose}
                  >
                    <Typography variant="h6">Cancel</Typography>
                  </CancelButton>
                  <RejectButton
                    id="reject_btn"
                    size="small"
                    variant="contained"
                    disabled={isInvalid}
                    onClick={rejectButton}
                  >
                    <Typography variant="h6">Reject</Typography>
                  </RejectButton>
                </DialogActions>
              </Dialog>
            </div>
            : ''}

          {/* eslint-disable-next-line no-mixed-operators */}
          {SupervisorPermission() || AdminPermission() && (props.timesheets.status === 'approved') ?
            (props.timesheets.status === 'pending' || props.timesheets.status === 'rejected' || props.timesheets.status === 'awaiting_approval' || props.timesheets.status === 'paid') ?
              ''
              :
              <div style={{ float: 'right', paddingTop: '10px', paddingBottom: '10px', '@media print': { display: 'none' }, }}>
                {ApprovalLockDataRef.current ?
                  ApprovalLockDataRef.current[0].lock === 'true' ?
                    <div>
                      <LockIcon style={{ marginBottom: '-10px' }} color="disabled" />
                      <ApproveButton
                        id="unapprove_btn"
                        size="small"
                        variant="contained"
                        disabled
                      >
                        Unapprove
                      </ApproveButton>
                    </div>

                    :
                    <div>
                      <ApproveButton
                        id="unapprove_btn"
                        size="small"
                        variant="contained"
                        onClick={unApproveButton}
                        disabled={props.timesheets.employee.status === 'deleted'}
                      >
                        Unapprove
                      </ApproveButton>

                    </div>
                  : ''}
              </div>
            : ''
          }
        </Grid>
      </Grid>
    </div>
  );
}